import React, { Component } from "react"

class Search extends Component {
  render() {
    return (
      <svg
        className={this.props.className}
        width="23px"
        height="23px"
        viewBox="0 0 23 23"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <g
            id="Group-4"
            transform="translate(1.000000, 1.000000)"
            stroke="#000000"
            strokeWidth="2"
          >
            <path d="M14,14 L20.5155501,20.5155501" id="Line-Copy-3" />
            <circle id="Oval" cx="7.5" cy="7.5" r="7.5" />
          </g>
        </g>
      </svg>
    )
  }
}

Search.defaultProps = {
  className: `icn__search`,
}

export default Search
