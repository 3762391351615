import React from "react"
import moment from "moment"
import axios from "axios"

const intervalDelay = 5000

class BuildDebug extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buildTime: null,
      lastDeployID: null,
      lastDeployUpdate: null,
      shouldReload: false,
      statusClassName: ``,
      visibleClassName: ``,
    }
    this.interval = null
  }

  componentDidMount() {
    // when component is mounted
    this.getNetlifyBuild()

    // then every few sec
    this.interval = setInterval(() => {
      this.getNetlifyBuild()
    }, intervalDelay)

    window.addEventListener(`scroll`, this.isVisible.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.isVisible.bind(this))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // set formated time when it change
    if (prevState.buildTime !== this.state.buildTime) {
      this.setTime()
    }

    // when DeployID has changed, tell user to reload the page
    if (
      prevState.lastDeployID !== null &&
      prevState.lastDeployID !== this.state.lastDeployID
    ) {
      this.setState({
        shouldReload: true,
        statusClassName: `is-alert`,
      })

      // clear invertval
      clearInterval(this.interval)
    }
  }

  isVisible() {
    this.setState({
      visibleClassName: window.scrollY < 25 ? `is-visible` : `is-faded`,
    })
  }

  getNetlifyBuild() {
    let url = `https://api.netlify.com/api/v1/sites/${
      process.env.GATSBY_NETLIFY_SITE_ID
    }/deploys/?access_token=${
      process.env.GATSBY_NETLIFY_ACCESS_TOKEN
    }&page=1&per_page=20`

    // get data
    axios
      .get(url)
      .then(response => {
        this.pickDeploy(response)
      })
      .catch(error => {
        console.log(error)
        clearInterval(this.interval)
      })
  }

  pickDeploy(response) {
    for (let index = 0; index < response.data.length; index++) {
      let deploy = response.data[index]
      if (deploy.state === `ready` && deploy.branch === `preview`) {
        // set deploy infos
        this.setState({
          lastDeployID: deploy.id,
          lastDeployUpdate: deploy.updated_at,
        })

        // set time for this deploy
        this.setTime()

        // exit loop on first matching deploy
        break
      }
    }
  }

  setTime(time) {
    // convert time string to Date object
    let m = moment(this.state.lastDeployUpdate)

    // set date if valid
    if (m._isValid) {
      this.setState({
        buildTime: `on ${m.format(`MMMM Do YYYY`)} at ${m.format(
          `h:mm:ss a`
        )} (${m.fromNow(`mm`)} ago)`,
      })
    }
  }

  render() {
    return (
      <div
        className={`
          gatsby__build_debug
          ${this.state.statusClassName}
          ${this.state.visibleClassName}
          d-none d-md-block
        `}
      >
        <div className="container-fluid">
          <p>
            Viewing site in preview mode. Last Contentful build was
            {` `}
            {this.state.buildTime}.
          </p>
          {this.state.shouldReload && (
            <p>This build is out of date, please reload your browser.</p>
          )}
        </div>
      </div>
    )
  }
}

export default BuildDebug
