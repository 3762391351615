import React from 'react'
import TypeIt from "typeit-react"

export default function WiedensimRandom(props) {
  const strings = () => {
    try {
      let strings = []
      props.data.edges.forEach((e) => {
        strings.push(e.node.wiedensim)
      })
      return strings
    } catch (error) {
      // console.log(error)
      return null
    }
  }
  return (
      <header className="wiedensim__random">
        <div className="wiedensim__single">
          <div className="h4">
            <TypeIt
                options={{
                strings: strings(),
                breakLines: false,
                loop: true,
                nextStringDelay: 4000,
                loopDelay: 2000,
                speed: 60,
                }}
            />
          </div>
        </div>
      </header>
  )
}
