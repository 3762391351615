import React from 'react';

export default function WKLogo() {
  return (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="213.086px"
        height="99.329px"
        viewBox="0 0 213.086 99.329"
        enableBackground="new 0 0 213.086 99.329"
      >
        <g>
          <g>
            <path
              d="M32.509,36.43h-4.642L18.381,0h3.482l8.477,33.15h0.101L41.087,0h3.986l11.101,33.15h0.101L64.7,0h3.179l-9.587,36.43
              h-4.491L43.055,3.381h-0.101L32.509,36.43z"
            />
            <path d="M75.801,3.784h-3.532V0h3.532V3.784z M75.548,11.101V36.43h-3.027V11.101H75.548z" />
            <path
              d="M84.781,24.471c0.101,5.399,2.573,9.94,8.779,9.94c3.582,0,6.559-2.573,7.316-6.055h3.179
              c-1.564,6.055-5.348,8.83-11.453,8.83c-7.568,0-11.101-6.509-11.101-13.421c0-6.862,3.784-13.421,11.302-13.421
              c8.477,0,11.555,6.206,11.555,14.128H84.781z M101.079,21.696c-0.353-4.894-2.977-8.577-8.224-8.577
              c-4.945,0-7.568,4.137-7.972,8.577H101.079z"
            />
            <path
              d="M128.375,32.696l-0.101-0.202c-2.069,3.027-5.147,4.692-8.679,4.692c-7.518,0-10.394-7.165-10.394-13.522
              c0-6.559,2.876-13.321,10.394-13.321c3.381,0,6.912,1.615,8.679,4.592l0.101-0.101V0h3.027v30.728
              c0,1.968,0.151,3.986,0.253,5.701h-3.128L128.375,32.696z M120.1,13.119c-5.752,0-7.619,5.348-7.619,10.192
              c0,4.692,1.363,11.101,7.165,11.101c6.61,0,8.628-5.5,8.628-11.252C128.275,18.013,126.004,13.119,120.1,13.119z"
            />
            <path
              d="M140.182,24.471c0.101,5.399,2.573,9.94,8.779,9.94c3.582,0,6.56-2.573,7.316-6.055h3.179
              c-1.564,6.055-5.348,8.83-11.454,8.83c-7.568,0-11.1-6.509-11.1-13.421c0-6.862,3.784-13.421,11.302-13.421
              c8.477,0,11.555,6.206,11.555,14.128H140.182z M156.479,21.696c-0.353-4.894-2.977-8.577-8.224-8.577
              c-4.945,0-7.568,4.137-7.972,8.577H156.479z"
            />
            <path
              d="M168.437,36.43h-3.027V16.802c0-1.968-0.152-3.986-0.253-5.701h3.128l0.151,3.734l0.101,0.101
              c1.766-3.128,4.642-4.592,7.922-4.592c8.275,0,8.931,7.316,8.931,10.243V36.43h-3.028V20.082c0-4.39-2.523-6.963-6.66-6.963
              c-4.995,0-7.266,4.188-7.266,8.729V36.43z"
            />
          </g>
          <g>
            <path d="M0,89.642V53.212h3.229l0.05,16.045l16.297-16.045h4.137L6.912,69.661l18.316,19.981h-4.491L3.28,70.62l-0.05,19.022H0z" />
            <path
              d="M29.618,77.683c0.101,5.399,2.573,9.94,8.779,9.94c3.582,0,6.559-2.574,7.316-6.055h3.179
              c-1.564,6.055-5.348,8.83-11.454,8.83c-7.568,0-11.101-6.509-11.101-13.421c0-6.862,3.784-13.421,11.302-13.421
              c8.477,0,11.555,6.206,11.555,14.128H29.618z M45.915,74.908c-0.353-4.894-2.977-8.578-8.224-8.578
              c-4.945,0-7.569,4.138-7.972,8.578H45.915z"
            />
            <path
              d="M57.873,89.642h-3.027V70.014c0-1.968-0.151-3.986-0.252-5.702h3.128l0.151,3.734l0.101,0.101
              c1.766-3.128,4.642-4.591,7.922-4.591c8.275,0,8.931,7.316,8.931,10.242v15.843h-3.027V73.294c0-4.39-2.523-6.963-6.66-6.963
              c-4.995,0-7.266,4.188-7.266,8.729V89.642z"
            />
            <path
              d="M84.514,89.642h-3.027V70.014c0-1.968-0.151-3.986-0.252-5.702h3.128l0.151,3.734l0.101,0.101
              c1.766-3.128,4.642-4.591,7.922-4.591c8.275,0,8.931,7.316,8.931,10.242v15.843H98.44V73.294c0-4.39-2.523-6.963-6.66-6.963
              c-4.995,0-7.266,4.188-7.266,8.729V89.642z"
            />
            <path
              d="M110.246,77.683c0.101,5.399,2.573,9.94,8.78,9.94c3.582,0,6.559-2.574,7.316-6.055h3.179
              c-1.564,6.055-5.348,8.83-11.454,8.83c-7.569,0-11.101-6.509-11.101-13.421c0-6.862,3.784-13.421,11.302-13.421
              c8.477,0,11.555,6.206,11.555,14.128H110.246z M126.543,74.908c-0.353-4.894-2.977-8.578-8.224-8.578
              c-4.945,0-7.568,4.138-7.972,8.578H126.543z"
            />
            <path
              d="M153.84,85.908l-0.101-0.202c-2.069,3.028-5.147,4.692-8.679,4.692c-7.518,0-10.394-7.165-10.394-13.522
              c0-6.559,2.876-13.32,10.394-13.32c3.381,0,6.912,1.615,8.679,4.591l0.101-0.101V53.212h3.027V83.94
              c0,1.968,0.151,3.986,0.253,5.702h-3.128L153.84,85.908z M145.565,66.331c-5.752,0-7.619,5.348-7.619,10.192
              c0,4.692,1.363,11.101,7.165,11.101c6.61,0,8.628-5.5,8.628-11.252C153.739,71.225,151.469,66.331,145.565,66.331z"
            />
            <path d="M165.344,64.313l8.527,21.192l8.476-21.192h3.28l-13.976,35.017h-3.33l3.835-9.688L161.66,64.313H165.344z" />
          </g>
          <polygon
            points="200.602,48.908 200.602,36.424 197.674,36.424 197.674,48.908 185.191,48.908 185.191,51.836 197.674,51.836
            197.674,64.32 200.602,64.32 200.602,51.836 213.086,51.836 213.086,48.908"
          />
        </g>
      </svg>
  );
}
