import React from 'react'
import PropTypes from "prop-types"
import { graphql } from 'gatsby'

import OfficeAddress from "./OfficeAddress"

export default function Offices(props) {
  return (
    <div className="offices-list row">
        {props.label && (
        <aside className="col-auto offices-list__label mb-3 d-none d-lg-block">
            <h3>Offices</h3>
        </aside>
        )}
        <aside className={`row offices-list__list`}>
        {props.offices.edges.map((office, index) => {
          return(
            <OfficeAddress
              displayNewBusinessContact={props.displayNewBusinessContact}
              officeDetailsLink={props.officeDetailsLink}
              key={index}
              office={office}
              columns={props.columns}
            />
        )})}
        </aside>
    </div>
  )
}
Offices.defaultProps = {
  label: true,
  displayNewBusinessContact: false,
  officeDetailsLink: false,
  columns: `col-6 col-md-3`,
}

Offices.propTypes = {
  offices: PropTypes.object.isRequired,
  displayNewBusinessContact: PropTypes.bool,
  officeDetailsLink: PropTypes.bool,
  columns: PropTypes.string,
}

export const officeFragment = graphql`
  fragment officeFragmentBase on ContentfulOffices {
    slug
    officeName
    shortName
    officeWebSite
    officeAddress {
      lon
      lat
    }
    officeAbout {
      childMarkdownRemark {
        html
      }
    }
    officeWebSite
    officeStreetAdress {
      id
      childMarkdownRemark {
        id
        html
      }
    }
    officeImage {
      id
      gatsbyImageData(width: 1800, quality: 80)
    }
    work {
      ...workFragmentBase
    }
    news {
      ...newsFragmentBase
    }
    contacts {
      ...contactFragment
    }
    newBusinessContact {
      ...contactFragment
    }
  }

  fragment officeFragment on ContentfulOffices {
    id
    slug
    officeName
    shortName
    officeWebSite
    officeAddress {
      lon
      lat
    }
    facebookUrl
    twitterUrl
    instagramUrl
    officeWebSite
    officeAbout {
      childMarkdownRemark {
        html
      }
    }
    officeStreetAdress {
      id
      childMarkdownRemark {
        id
        html
      }
    }
    officeImage {
      id
      gatsbyImageData(width: 1800, quality: 80)
    }
    videoHeader {
      id
      file {
        url
        fileName
        contentType
      }
    }
    work {
      ...workFragmentBase
    }
    selectedWork {
      ...workFragmentBase
    }
    news {
      ...newsFragmentBase
    }
    longform {
      ...longformFragment
    }
    contacts {
      ...contactFragment
    }
  }
`