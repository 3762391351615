import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Offices from "../content/offices/Offices"
import NavItems from "./NavItems"
import SocialLinks from "./SocialLinks"

const NavMain = props => (
  <nav className={`nav-main ${props.NavState.className} tohide`}>
    <div className="container-fluid">
      <div className="row auto-height">
        {/* nav items */}
        <div className="col-12 align-self-start">
          <NavItems row="row" col="col-auto no-padding" blurb={true} />
        </div>

        {/* social */}
        <div className="nav-main__social col-12 order-md-3 no-padding__left-md">
          <div className="col-12 no-padding">
            <hr className="d-none d-md-block" />
            <SocialLinks />
          </div>
        </div>

        {/* offices */}
        <div className="col-12 align-self-end no-padding__left no-padding__right">
          <Offices offices={props.offices} />
        </div>
      </div>
    </div>
  </nav>
)

NavMain.propTypes = {
  offices: PropTypes.object.isRequired,
}

const mapStateToProps = store => {
  return { NavState: store.NavState }
}

export default connect(mapStateToProps)(NavMain)
