import React from 'react';

export default function SocialLinks() {
  return (
    <nav className="social_links">
        <p className="h5 is-gray-light mb-2 d-none d-md-block">Follow us on</p>
        <ul className={`is-unstyled is-inlined`}>
        {/* <li>
            <a
            href="https://www.facebook.com/wiedenkennedy/"
            target="_blank"
            rel="noopener noreferrer"
            >
            Facebook
            </a>
        </li> */}
        <li>
            <a
            href="https://twitter.com/wiedenkennedy"
            target="_blank"
            rel="noopener noreferrer"
            >
            Twitter
            </a>
        </li>
        <li>
            <a
            href="https://www.instagram.com/wiedenkennedy/"
            target="_blank"
            rel="noopener noreferrer"
            >
            Instagram
            </a>
        </li>
        </ul>
    </nav>
  );
}
