import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'

const PageAttributes = props => {
  const siteName = `Wieden+Kennedy`
  const siteDescription = `Integrated Advertising Campaigns`
  const sep = `|`

  const defaultTitle = () => `${siteName} ${sep} ${siteDescription}`

  const title = () => {
    if (props.seoFields) {
      if (props.seoFields.seoTitle) {
        return `${props.seoFields.seoTitle} ${sep} ${siteName}`
      } else if (props.title) {
        return `${props.title} ${sep} ${siteName}`
      } else {
        return defaultTitle()
      }
    } else {
      return props.title ? `${props.title} ${sep} ${siteName}` : defaultTitle()
    }
  }

  const description = () => {
    if (props.seoFields) {
      if (props.seoFields.seoDescription) {
        return props.seoFields.seoDescription.seoDescription
      } else if (props.description) {
        return props.description
      }
    } else if (props.description) {
      return props.description
    }
  }

  const image = () => {
    if (props.seoFields && props.seoFields.seoImage) {
      return props.seoFields.seoImage.file.url
    } else if (props.image) {
      return props.image
    }
  }
  // console.log(props)
  return (
    <Helmet
      defaultTitle="Wieden+Kennedy | Full Service Integrated Advertising Agency"
      title={title()}
      mateDescription={description()}
      bodyAttributes={{
        class: props.bodyClass,
      }}
    >
      <meta name="description" content={description()} />
      <meta property="og:title" content={title()} />
      <meta property="og:description" content={description()} />
      <meta property="og:image" content={image()} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title()} />
      <meta name="twitter:description" content={description()} />
      <meta name="twitter:image" content={image()} />
      <meta
        name="ahrefs-site-verification"
        content="60731af8a710220fa57373aeca408f14e0fb65e8dc1322d66fb4e2c5621f9da1"
      />
    </Helmet>
  )
}

PageAttributes.defaultProps = {
  title: null,
  seoFields: {
    seoTitle: null,
    seoDescription: null,
    seoImage: null,
  },
  bodyClass: null,
}

PageAttributes.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  seoFields: PropTypes.shape({
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.object,
    seoImage: PropTypes.object,
  }),
  bodyClass: PropTypes.string,
}

export default PageAttributes

//
// Each SEO fields
//
export const seoFieldsFragment = graphql`
  fragment seoFieldsFragment on ContentfulSeoFields {
    id
    slug
    seoTitle
    seoDescription {
      seoDescription
    }
    seoImage {
      file {
        url
      }
    }
  }
`
