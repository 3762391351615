import React from "react"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"
import { resetSearch, resetHeader } from "../actions"
import Search from "../svg/Search"
import Close from "../svg/Close"

class SearchIcon extends React.Component {
  constructor(props) {
    super(props)

    // register events
    this.handle = this.handle.bind(this)
  }

  handle = e => {
    e.preventDefault()
    if (
      this.props.SearchState.hasSearchResults &&
      this.props.SearchState.isSearchPage
    ) {
      this.props.resetSearch()
    } else if (this.props.SearchState.isSearchPage) {
      if(typeof window !== `undefined`){
        window.history.back()
      }
    } else {
      navigate(`/search/`)
    }
  }

  icon = () => {
    if (this.props.SearchState.isSearchPage) {
      return <Close />
    } else {
      return <Search />
    }
  }

  render() {
    return (
      <Link
        to={`/search/`}
        onClick={e => this.handle(e)}
        className={`
          nav-main__search_icon
          ${this.props.NavState.className}
          ${this.props.SiteHeaderState.burgerClassName}
        `}
      >
        {this.icon()}
      </Link>
    )
  }
}

const mapStateToProps = store => {
  return {
    NavState: store.NavState,
    SiteHeaderState: store.SiteHeaderState,
    SearchState: store.SearchState,
  }
}

export default connect(
  mapStateToProps,
  {
    resetSearch,
    resetHeader,
  }
)(SearchIcon)
