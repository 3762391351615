import React, { Component } from "react"

class ContactPreview extends Component {
  constructor(props) {
    super(props)
    this.contact = this.props.contact.node
      ? this.props.contact.node
      : this.props.contact
  }

  render() {
    let WrapElement = `span`
    let attributes = {}

    // link to email
    if (this.contact.contactEmail && this.props.emailTo) {
      WrapElement = `a`
      attributes = {
        ...{
          href: `mailto:${this.contact.contactEmail}`,
        },
      }
    }

    return (
      <WrapElement
        {...attributes}
        className={`contact__preview ${this.props.col}`}
      >
        {this.contact.contactType && (
          <span className="contact__type">{this.contact.contactType.name}</span>
        )}
        <span className="contact__fullname">
          {this.contact.contactFullName}
        </span>
        {this.props.details && (
          <footer className="contact__preview__details">
            {this.contact.contactEmail && (
              <p className="contact__preview__details__email">
                <a href={`mailto:${this.contact.contactEmail}`}>
                  {this.contact.contactEmail}
                </a>
              </p>
            )}
            {this.contact.contactPhone && (
              <p className="contact__preview__details__phone">
                {this.contact.contactPhone}
              </p>
            )}
          </footer>
        )}
      </WrapElement>
    )
  }
}

ContactPreview.defaultProps = {
  emailTo: false,
  linkToQA: false,
  details: false,
  col: ``,
}

export default ContactPreview
