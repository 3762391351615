import React from 'react'
import { Link } from 'gatsby'

import NavItems from './NavItems'
import SocialLinks from './SocialLinks'
import Offices from "../content/offices/Offices"

export default function SiteFooter(props) {
    return (
        <footer id="footer" className="site-footer tohide">
            <div className="container-fluid site-footer__content">
                <div className="row">
                    <nav className="site-footer__nav col-12 col-lg-2">
                    <NavItems row={false} column={false} contactLink={true} />
                    </nav>
                    <aside className="site-footer__offices col-12 col-lg-10">
                    <Offices
                        displayNewBusinessContact={false}
                        officeDetailsLink={false}
                        offices={props.offices}
                        label={false}
                        columns="col-12 col-sm-6 col-md-3 col-lg-3"
                    />
                    </aside>
                </div>
            </div>
            <div className="container-fluid site-footer__legal">
                <div className="row">
                    <aside className="col-auto mr-md-auto">
                    <p>
                        &copy; Wieden Kennedy {new Date().getFullYear()} ·  <Link to="/legal">Legal Stuff</Link>
                    </p>
                    </aside>
                    <nav className="col-auto ml-md-auto">
                    <SocialLinks navClassName={``} />
                    </nav>
                </div>
            </div>
        </footer>
    )
}