import React from "react"
import PropTypes from "prop-types"
import tzlookup from "tz-lookup"
import moment from "moment-timezone"
import Clock from "react-clock"

let interval
let openingHour = 6
let closingHour = 20

class OfficeClock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tz: tzlookup(this.props.lat, this.props.lon),
      date: null,
      statusClassName: `is-open`,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setTime()
    }, 1000 * 60)

    // force update on mount
    this.setTime()
  }

  setTime() {
    let dateTZ = moment.tz(this.state.tz)
    let openingAt = moment.tz(this.state.tz).hour(openingHour)
    let closingAt = moment.tz(this.state.tz).hour(closingHour)
    let open = dateTZ.isBetween(openingAt, closingAt)

    this.setState({
      // This is a stupid fix for Safari, converting the Moment object a 2nd time
      // then converted to a native Date object
      date: moment(dateTZ.format(`YYYY-MM-DD HH:mm:ss`)).toDate(),
      statusClassName: open ? `is-open` : `is-closed`,
    })
  }

  componentWillUnmount() {
    clearInterval(interval)
  }

  render() {
    return (
      <date className={`office__clock ${this.state.statusClassName}`}>
        <Clock
          value={this.state.date}
          size={28}
          renderMinuteMarks={false}
          renderHourMarks={false}
          renderSecondHand={false}
          minuteHandLength={70}
          secondHandLength={80}
          hourHandOppositeLength={0}
          minuteHandOppositeLength={0}
          secondHandOppositeLength={0}
          secondHandWidth={1}
          minuteHandWidth={1}
          hourHandWidth={1}
        />
      </date>
    )
  }
}

OfficeClock.propTypes = {
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
}

export default OfficeClock