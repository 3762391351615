import React from "react"
import LinkTo from "gatsby-link"
import Close from "../svg/Close"

class PrivacyNotices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
    }
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    const privacyConcent = localStorage.getItem(`privacyConcent`)
    if (privacyConcent === `read`) {
      this.setState({
        isOpen: false,
      })
    }
  }

  handleClose() {
    this.setState({
      isOpen: false,
    })
    localStorage.setItem(`privacyConcent`, `read`)
  }

  render() {
    const visibleClassName = this.state.isOpen ? `is-visible` : `is-hidden`

    return (
      <div className={`dev_notices dev_notices__privacy ${visibleClassName}`}>
        <div className="container-fluid">
          <p>
            You can see our updated
            {` `}
            <LinkTo to="/legal" onClick={e => this.handleClose(e)}>
              Privacy Policy here.
            </LinkTo>
            <button className="close" onClick={e => this.handleClose(e)}>
              <Close />
            </button>
          </p>
        </div>
      </div>
    )
  }
}

export default PrivacyNotices
