import React from 'react'
import { Link } from 'gatsby'

import { useDispatch } from 'react-redux'


export default function NavItems(props) {
  const dispatch = useDispatch()
  const transitionDefaultTimeout = 750
  const TransitionFn = () => {
    dispatch({ type: "START_TRANSITION" })

    setTimeout(() => {
        dispatch({ type: "CLOSE_NAV" })
    }, transitionDefaultTimeout / 2)

    setTimeout(() => {
        // start store dispatch
        dispatch({ type: "END_TRANSITION" })
    }, transitionDefaultTimeout)

    setTimeout(() => {
        dispatch({ type: "RESET_TRANSITION" })
    }, transitionDefaultTimeout * 2)
  }
  return (
    <ul className={`nav-main__items is-unstyled ${props.row}`}>
        <li className={`nav-main__item ${props.col}`}>
        <Link to="/work/" partiallyActive={true} activeClassName="is-active" onClick={() => TransitionFn()}>
            Work
        </Link>
        {props.blurb && (
            <p className="nav-main__blurb">
            It comes first. Creative projects from around the network.
            </p>
        )}
        </li>
        {/* <li className={`nav-main__item ${props.col}`}>
        <Link to="/culture/" partiallyActive={true} activeClassName="is-active" onClick={() => TransitionFn()}>
            Culture
        </Link>
        {props.blurb && (
            <p className="nav-main__blurb">
            All about working here, and the people who do.
            </p>
        )}
        </li> 
        <li className={`nav-main__item ${props.col}`}>
        <Link to="/news/" partiallyActive={true} activeClassName="is-active" onClick={() => TransitionFn()}>
            News
        </Link>
        {props.blurb && (
            <p className="nav-main__blurb">
            The latest media coverage + insights from us.
            </p>
        )}
        </li>*/}
        <li className={`nav-main__item ${props.col}`}>
        <Link to="/about/" partiallyActive={true} activeClassName="is-active" onClick={() => TransitionFn()}>
            About 
        </Link>
        {props.blurb && (
            <p className="nav-main__blurb">Who we are, where to find us.</p>
        )}
        </li>
        {/* <li className={`nav-main__item ${props.col}`}>
        <Link to="/jobs/" partiallyActive={true} activeClassName="is-active" onClick={() => TransitionFn()}>
            Jobs
        </Link>
        {props.blurb && (
            <p className="nav-main__blurb">Explore open jobs across our network.</p>
        )}
        </li> */}
        {props.contactLink && (
        <li className={`nav-main__item ${props.col}`}>
            <Link to="/contact/" partiallyActive={true} activeClassName="is-active" onClick={() => TransitionFn()}>
            Contact
            </Link>
            {props.blurb && <p className="nav-main__blurb">How to get in touch.</p>}
        </li>
        )}
    </ul>
  )
}
NavItems.defaultProps = {
  blurb: false,
  row: ``,
  col: ``,
  contactLink: true,
}