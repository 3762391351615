import React from "react"
import { connect } from "react-redux"
import { openNav, closeNav } from "../actions"

class Burger extends React.Component {
  constructor(props) {
    super(props)

    // register events
    this.handle = this.handle.bind(this)
  }

  handle() {
    if (
      typeof this.props.NavState.closed == `undefined` ||
      this.props.NavState.closed
    ) {
      this.props.openNav()
    } else {
      this.props.closeNav({
        revertInverted: this.props.SiteHeaderState.inverted,
      })
    }
  }

  render() {
    return (
      <button
        onClick={this.handle}
        className={`nav-main__burger hamburger hamburger--3dy ${
          this.props.NavState.burgerClassName
        } ${this.props.SiteHeaderState.burgerClassName}`}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    )
  }
}

const mapStateToProps = store => {
  return {
    NavState: store.NavState,
    SiteHeaderState: store.SiteHeaderState,
  }
}

export default connect(
  mapStateToProps,
  {
    openNav,
    closeNav,
  }
)(Burger)
