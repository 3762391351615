import React from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types"

import OfficeClock from "./OfficeClock"
import ContactPreview from "../contacts/ContactPreview"

import nl2br from "../../utils/nl2br"

export default function OfficeAddress(props) {
    // define objects
    let {
        id,
        slug,
        officeWebSite,
        officeName,
        officeAddress,
        officeStreetAdress,
        newBusinessContact,
    } = props.office.node != null ? props.office.node : props.office

    const streetAddressHTML = () =>
        officeStreetAdress !== null
        ? nl2br(officeStreetAdress.childMarkdownRemark.html)
        : ``  
  return (
    <article key={id} className={`office__address ${props.columns}`}>
      <div className="row">
        {props.displayClock && (
          <aside className="col-auto no-padding">
            {officeAddress && (
              <OfficeClock lat={officeAddress.lat} lon={officeAddress.lon} />
            )}
          </aside>
        )}
        <aside className="col-9 col-sm-10 office__address__details">
          {props.displayName && (
            <h3 className="office__name">
              <Link to={`/about/office/${slug}`}>{officeName}</Link>
            </h3>
          )}
          {props.displayAddress && (
            <div
              className="office__street-address"
              dangerouslySetInnerHTML={{ __html: streetAddressHTML() }}
            />
          )}
          {officeWebSite &&
            props.link && (
              <a
                href={officeWebSite}
                target="_blank"
                rel="noopener noreferrer"
                className="btn__link"
              >
                Link to office website
              </a>
            )}
          {props.displayNewBusinessContact &&
            newBusinessContact && (
              <ContactPreview contact={newBusinessContact} details={true} />
            )}

          {props.officeDetailsLink && (
            <a href={`/about/office/${slug}`} className="btn__link">
              Office details and contacts here
            </a>
          )}
        </aside>
      </div>
    </article>
  )
}
OfficeAddress.defaultProps = {
  displayName: true,
  displayClock: true,
  displayAddress: true,
  displayNewBusinessContact: false,
  columns: `col-6 col-lg-3`,
}

OfficeAddress.propTypes = {
  office: PropTypes.object.isRequired,
  displayName: PropTypes.bool,
  displayClock: PropTypes.bool,
  displayAddress: PropTypes.bool,
  displayNewBusinessContact: PropTypes.bool,
  columns: PropTypes.string,
}