import React, { Component } from "react"

// Redux
import { startTransition, endTransition } from "../actions"
import { connect } from "react-redux"

const transitionTypes = {
  fade: {
    type: `fade`,
    in: `page_transition__fade__in`,
    out: `page_transition__fade__out`,
  },
  swipe: {
    type: `swipe`,
    in: `page_transition__swipe__in`,
    out: `page_transition__swipe__out`,
  },
}

class PageTransition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteHasLoaded: false,
    }
  }

  componentDidUpdate() {
    document.body.classList.toggle(
      `in-transition`,
      this.props.PageTransitionState.active
    )
    document.body.classList.toggle(
      transitionTypes[this.props.PageTransitionState.transitionType].type,
      this.props.PageTransitionState.inTransition
    )
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        siteHasLoaded: true,
      })
    }, 100)
  }

  className() {
    let className = []

    // set transition type
    if (this.props.PageTransitionState.inTransition === true) {
      className.push(
        transitionTypes[this.props.PageTransitionState.transitionType].in
      )
    } else if (this.props.PageTransitionState.inTransition === false) {
      className.push(
        transitionTypes[this.props.PageTransitionState.transitionType].out
      )
    } else {
      className.push(`page_transition`)
    }

    // set visible if is active
    if (this.props.PageTransitionState.active) {
      className.push(`is-visible`)
    } else {
      className.push(`is-hidden`)
    }

    return className.join(` `)
  }
  
  render() {
    return (
      <div className="page_transitions">
        <div
          ref="blocker"
          className={`page_transition__blocker ${
            this.state.siteHasLoaded ? `has-loaded` : ``
          }`}
        />
        <div className={this.className()}>
          <div className="inner" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    PageTransitionState: store.PageTransitionState,
  }
}

export default connect(
  mapStateToProps,
  {
    startTransition,
    endTransition,
  }
)(PageTransition)
